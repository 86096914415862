export const slist = [
  {
    "id":1,
    "title":"潮祥记海鲜粥（靖江人民北路店）",
    "star":4.9,
    "sales":12450,
    "type":"西式快餐",
    "address":"德诚广场",
    "distance":"26m",
    "status":"休息中10:00营业",
    "discounts":[
      {"head":"团","info":"¥69.00 百随宫烤肉火锅海鲜自助店单人自助晚餐"},
      {"head":"团","info":"¥49.00 百随宫烤肉火锅海鲜自助店单人自助夜宵"},
      {"head":"券","info":"¥95.00 百随宫烤肉火锅海鲜自助店100元代金券"},
      {"head":"买","info":"在线买单立享满300减10元优惠"}
    ]
  },
  {
    "id":2,
    "title":"杨铭宇黄焖鸡米饭（人民桥店）",
    "star":0,
    "sales":0,
    "type":"黄焖鸡",
    "address":"德诚广场",
    "distance":"485m",
    "status":"即将打烊",
    "discounts":[
      {"head":"团","info":"¥13.80 单人小份黄焖鸡米饭套餐"},
      {"head":"买","info":"在线买单立享满300减10元优惠"}
    ]
  },
  {
    "id":3,
    "title":"如意馄饨（泰和店）",
    "star":4.9,
    "sales":12450,
    "type":"中式快餐",
    "address":"泰和广场",
    "distance":"226m",
    "status":"营业至23:30",
    "discounts":[
      {"id":"1","head":"团","info":"¥69.00 百随宫烤肉火锅海鲜自助店单人自助晚餐"},
      {"id":"2","head":"团","info":"¥49.00 百随宫烤肉火锅海鲜自助店单人自助夜宵"},
      {"id":"3","head":"券","info":"¥95.00 百随宫烤肉火锅海鲜自助店100元代金券"},
      {"id":"4","head":"买","info":"在线买单立享满300减10元优惠"}
    ]
  },
]
export const rlist = [
  {
    "title":"CoCo都可（胜利街店）100元代金券",
    "name":"刘府老灶门四川老火锅（靖江店）",
    "address":"市中心",
    "distance":"10km",
    "sales":"12450",
    "rank":"1",
    "img":"https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2417345571,3877558845&fm=26&gp=0.jpg",
    "now_price":"89.9",
    "ori_price":"100"
  },
  {
    "title":"CoCo都可（胜利街店）100元代金券",
    "name":"刘府老灶门四川老火锅（靖江店）",
    "address":"市中心",
    "distance":"10km",
    "sales":"12450",
    "rank":"1",
    "img":"https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2417345571,3877558845&fm=26&gp=0.jpg",
    "now_price":"89.9",
    "ori_price":"100"
  },
  {
    "title":"CoCo都可（胜利街店）100元代金券",
    "name":"刘府老灶门四川老火锅（靖江店）",
    "address":"市中心",
    "distance":"10km",
    "sales":"12450",
    "rank":"4",
    "img":"https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2417345571,3877558845&fm=26&gp=0.jpg",
    "now_price":"89.9",
    "ori_price":"100"
  }
]

export const storeRankList = [
  {
    "title":"CoCo都可（胜利街店）",
    "star":4.9,
    "type":"西式快餐",
    "address":"德城广场",
    "distance":"10km",
    "sales":"12450",
    "rank":"1",
    "img":"https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2417345571,3877558845&fm=26&gp=0.jpg",
    "labels":["人气商家","文艺清新","后台自定义内容"]
  },
  {
    "title":"CoCo都可（胜利街店）",
    "star":4.9,
    "type":"西式快餐",
    "address":"德城广场",
    "distance":"10km",
    "sales":"12450",
    "rank":"2",
    "img":"https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2417345571,3877558845&fm=26&gp=0.jpg",
    "labels":["人气商家","文艺清新","后台自定义内容"]
  },
  {
    "title":"CoCo都可（胜利街店）",
    "star":4.9,
    "type":"西式快餐",
    "address":"德城广场",
    "distance":"10km",
    "sales":"12450",
    "rank":"3",
    "img":"https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2417345571,3877558845&fm=26&gp=0.jpg",
    "labels":["人气商家","文艺清新","后台自定义内容"]
  },
  {
    "title":"CoCo都可（胜利街店）",
    "star":4.9,
    "type":"西式快餐",
    "address":"德城广场",
    "distance":"10km",
    "sales":"12450",
    "rank":"4",
    "img":"https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2417345571,3877558845&fm=26&gp=0.jpg",
    "labels":["人气商家","文艺清新","后台自定义内容"]
  },
]