<template>
  <div>
    <Search navType="search" :sty="sty"></Search>
    <div class="imgs">
      <Swipe :autoplay="3000" class="swiperCon">
        <SwipeItem v-for="(image, index) in images" :key="index" class="swiperCon-item">
          <img v-lazy="image" />
        </SwipeItem>
      </Swipe>
    </div>
    <Nav :initDataNav="initData"></Nav>
    <div class="banner"><img src="http://img3.imgtn.bdimg.com/it/u=309842420,1506146809&fm=26&gp=0.jpg" alt=""></div>
    <div class="type-name">为你推荐<span @click="more()">更多></span></div>
    <div class="psr">
      <div class="half-item" v-for="item in list1" :key="item.id"></div>
    </div>
    <div class="type-name">优惠专区</div>
    <div class="psr">
      <div class="half-item"></div>
      <div class="half-item"></div>
      <div class="quarter-item"></div>
      <div class="quarter-item"></div>
      <div class="quarter-item"></div>
      <div class="quarter-item"></div>
    </div>
    <!-- <div class="type-name">好店推荐<span>更多></span></div> -->
    <!-- <div class="adbox"></div> -->
    <dropdown-menu>
      <dropdown-item v-model="value1" :options="option1" />
      <dropdown-item v-model="value2" :options="option2" />
      <dropdown-item v-model="value3" :options="option3" />
      <dropdown-item v-model="value4" :options="option4" />
    </dropdown-menu>
    <store-list :storeList="storeList"></store-list>
  </div>  
</template>

<script>
import Search from "@components/public/searchTop";
import { Swipe, SwipeItem, DropdownMenu, DropdownItem } from 'vant';
import Nav from "@components/public/nav";
import StoreList from "@components/public/storeList";
import {slist} from "./mydata"
export default {
  data(){
    return{
      sty:{background:'#fff'},
      images: [
        'https://img.yzcdn.cn/vant/apple-1.jpg',
        'https://img.yzcdn.cn/vant/apple-2.jpg',
        'https://cs.baidu.com/img/bd_logo1.png'
      ],
      list1:[{id:1},{id:2},{id:3},{id:4},{id:5},{id:6}],
      storeList:[],
      initData: [
        {
          id: 0,
          pic: require("../../../../public/static/images/home/tuangou.png"),
          title: "乐享团购",
          url: ""
        },
        {
          id: 1,
          pic: require("../../../../public/static/images/home/shangcheng.png"),
          title: "乐享商城",
          url: "/enjoyshop"
        },
        {
          id: 2,
          pic: require("../../../../public/static/images/home/qiangou.png"),
          title: "限时抢购",
          url: ""
        },
        {
          id: 3,
          pic: require("../../../../public/static/images/home/miandan.png"),
          title: "助力免单",
          url: ""
        },
        {
          id: 4,
          pic: require("../../../../public/static/images/home/kanjia.png"),
          title: "好友砍价",
          url: ""
        },
        {
          id: 5,
          pic: require("../../../../public/static/images/home/qiandao.png"),
          title: "每日签到",
          url: ""
        },
        {
          id: 6,
          pic: require("../../../../public/static/images/home/zhuanpan.png"),
          title: "积分转盘",
          url: ""
        },
        {
          id: 7,
          pic: require("../../../../public/static/images/home/youli.png"),
          title: "储值有礼",
          url: ""
        },
        {
          id: 8,
          pic: require("../../../../public/static/images/home/lingquan.png"),
          title: "领券中心",
          url: ""
        },
        {
          id: 9,
          pic: require("../../../../public/static/images/home/shangjiaruzhu.png"),
          title: "商家入住",
          url: ""
        }
      ],
      value1: 0,
      value2: 'a',
      value3: 0,
      value4: 0,
      option1: [
        { text: '全部分类', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ],
      option2: [
        { text: '附近', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' },
      ],
      option3: [
        { text: '综合排序', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ],
      option4: [
        { text: '筛选', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ],
    }
  },
  mounted(){
    this.testFn()
  },
  methods:{
    testFn(){
      console.log(slist)
      this.storeList = slist
    },
    more(){
      var item = this.storeList[0]
      item.id = this.storeList.length + 2
      this.storeList.push(item)
    },
  },
  components:{
    Search,
    Swipe,
    SwipeItem,
    Nav,
    DropdownMenu, 
    DropdownItem,
    StoreList
  }
}
</script>

<style lang="scss" scoped>
.imgs{
  width:100%;
  height: 160px;
  margin-top:45px;
  .swiperCon {
    width: 92%;
    margin: 0 auto;
    .swiperCon-item {
      width: 100%;
      height: 100%;
      img {
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 160px;
      }
    }
  }
}
.banner{
  width:92%;
  height: 98px;
  margin:10px auto;
  img{
    display: block;
    width:100%;
    height: 100%;
  }
}
.type-name{
  width:92%;
  height: 36px;
  font-size: 14px;
  line-height: 40px;
  margin:0 auto;
  color: #000;
  font-family:PingFang SC;
  font-weight:500;
  span{
    color:#8c8c8c;
    font-size: 12px;
    float:right;
  }
}
.psr{
  position: relative;
  display: flex;
  width:92%;
  margin:0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  .half-item{
    width:48%;
    height: 116px;
    border:1px solid #eee;
    box-sizing:border-box;
    margin-bottom:10px;
  }
  .quarter-item{
    width:23%;
    height: 116px;
    border:1px solid #eee;
    box-sizing:border-box;
    margin-bottom:10px;
  }
}

</style>